import React from 'react'
import { connect } from 'react-redux'
import Layout from '../components/layout'
import SEO from '../components/seo'
import CookieNotice from '../components/shared/CookieNotice'

const NotFoundPage = ({ declarePageAsNotLanding }) => {
  declarePageAsNotLanding()
  return (
    <Layout>
      <SEO title='404: Not found' />
      <CookieNotice />
      <h1>404: Not found | Game-changing esports analytics - Shadow</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    declarePageAsNotLanding: () => dispatch({ type: `SET_LANDING_PAGE_FALSE` }),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotFoundPage)
